import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import * as EmailValidator from "email-validator"

import theme from "../themes/theme"

import { PageLayout } from "../components/PageLayout"

import { Blurb, BlurbIcon, BlurbHeading, BlurbHeader, BlurbText } from "../components/Blurb"

import { GoChecklist, GoGraph } from "react-icons/go"
import { FaRegUser } from "react-icons/fa"
import { AiOutlineMail } from "react-icons/ai"
import { GiAmericanFootballBall } from "react-icons/gi"

import { Responsive2ColumnContainer, Responsive2ColumnItem } from "../components/Responsive2Column"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
  OneColumnSection,
  DivHideOnMobile,
  ColumnWideMobileReducedDesktop,
  RowDesktopColumnMobile,
} from "../components/SectionBlocks"

import { HeroHeadline, HeroHeadlineLanding, HeroSubHeadLanding } from "../components/HeroSection"

import SEO from "../components/seo"
import { ButtonSecondary, FormButtonSecondary } from "../components/Buttons"

const FootyThankYou = ({ data, location }) => {
  return (
    <PageLayout>
      <SEO
        title="Get Ready For Footy Season"
        ogImage={data?.socialImage?.childImageSharp?.fixed.src}
        description="Get Footy Fit With Out 60 Day Pre-Season Training Prep"
        url={location.origin + location.pathname}
      />
      <RowSection
        rowMargin="0%"
        backgroundUrl={data?.heroImage?.childImageSharp?.fluid}
        bgX="50%"
        bgY="0%"
        style={{ minHeight: "85vh" }}>
        <OneColumnSection
          style={{
            width: "100vw",
            paddingLeft: "10px",
            paddingRight: "10px",
            justifyContent: "space-evenly",
          }}>
          <HeroHeadlineLanding>
            <h1 style={{ color: theme.colors.white, textAlign: "center", align: "center" }}>
              You're On Your Way To A{" "}
              <span style={{ color: theme.colors.primaryBlueNormal }}>Great</span> Season!
            </h1>
          </HeroHeadlineLanding>
        </OneColumnSection>
      </RowSection>
    </PageLayout>
  )
}

export default FootyThankYou

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    heroImage: file(absolutePath: { regex: "/rugby-league-running-tinted-1920x1080-16x9.jpg/" }) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    socialImage: file(absolutePath: { regex: "/rugby-league-running-tinted-1920x1080-16x9.jpg/" }) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
